import FastClick from './fast-click';
import DomMap from './dom-map';
import states from './states';
import 'confetti-js';
import Util from './util';

var showConfetti = () => {
    var id = 'confetti';
    var el = document.getElementById(id);
    var ConfettiGenerator = window.ConfettiGenerator
    var Confetti = new ConfettiGenerator({ target: id });

    el.style.display = 'block';
    Confetti.render();
}

var Deck = {
    getCard: function(deck, suit, rank) {
        for (var i = 0; i < deck.cards.length; i++) {
            if (deck.cards[i].suit === suit && deck.cards[i].rank === rank) {
                return deck.cards[i];
            }
        }
    },

    shuffle: function(deck) {
        deck.cards = Deck.shuffleArray(deck.cards);
    },

    shuffleArray: function (toShuffle) {
        // Make a copy of the array that we can pull items from
        var arrayCopy = toShuffle.slice(0);

        // Create the target array
        var newArray = new Array(arrayCopy.length);

        /* This function will attempt to place a value at the given index
         If that index is already taken it will try the next spot,
         wrapping to the beginning of the array if necessary */
        var placeAt = function(index, value) {
            if (newArray[index] === undefined) {
                newArray[index] = value;
            }
            else {
                if (index + 1 === newArray.length) {
                    placeAt(0, value);
                }
                else {
                    placeAt(index + 1, value);
                }
            }
        };

        // Pull a random element from the array and insert it into
        // a random place in the new array
        for (var i = 0; i < newArray.length; i++) {
            // Choose a random element to work with
            var index = Math.floor((Math.random() * arrayCopy.length));
            placeAt(Math.floor(Math.random() * arrayCopy.length), arrayCopy[index]);

            // Remove that element from the old array
            arrayCopy.splice(index, 1);
        }

        return newArray;
    },

    createDeck: function() {
        var result = {
            cards: []
        };

        for (var suit = 0; suit <= 3; suit++) {
            var suitValue;
            switch (suit) {
                case 0:
                    suitValue = 'club';
                    break;
                case 1:
                    suitValue = 'spade';
                    break;
                case 2:
                    suitValue = 'heart';
                    break;
                case 3:
                    suitValue = 'diamond';
                    break;
                default:
                    throw new Error('shouldnt be getting here!')
            }
            result.cards.push(Card.createCard(suitValue, 'A'));
            result.cards.push(Card.createCard(suitValue, '2'));
            result.cards.push(Card.createCard(suitValue, '3'));
            result.cards.push(Card.createCard(suitValue, '4'));
            result.cards.push(Card.createCard(suitValue, '5'));
            result.cards.push(Card.createCard(suitValue, '6'));
            result.cards.push(Card.createCard(suitValue, '7'));
            result.cards.push(Card.createCard(suitValue, '8'));
            result.cards.push(Card.createCard(suitValue, '9'));
            result.cards.push(Card.createCard(suitValue, '10'));
            result.cards.push(Card.createCard(suitValue, 'J'));
            result.cards.push(Card.createCard(suitValue, 'Q'));
            result.cards.push(Card.createCard(suitValue, 'K'));
        }

        Deck.shuffle(result);

        return result;
    }
};

var Card = {
    equals: function(card1, card2) {
        return card1.rank === card2.rank && card1.suit === card2.suit;
    },

    findNumericRank: function(card) {
        switch (card.rank) {
            case 'A':
                return 1;
            case 'J':
                return 11;
            case 'Q':
                return 12;
            case 'K':
                return 13;
            default:
                return parseInt(card.rank);
        }
    },

    updateLayout: function(card, x, y, rotation, scale) {
        if (x !== card.x || y !== card.y || rotation !== card.rotation || scale !== card.scale) {
            card.x = x;
            card.y = y;
            card.rotation = rotation;
            card.scale = scale;

            var transformDefinition = 'translateX(' + x + 'px) translateY(' + y + 'px) translateZ(1px)';
            if (rotation !== undefined && rotation !== 0) {
                transformDefinition += ' rotateZ(' + rotation + 'deg)';
            }
            if (scale !== undefined && scale !== 1) {
                transformDefinition += ' scale(' + scale + ')';
            }
            DomMap[card.rank + '_' + card.suit].style.transform = transformDefinition;
            // DomMap[card.rank + '_' + card.suit].style[Modernizr.prefixed('transform')] = transformDefinition;
        }
    },

    setZ: function(card, z) {
        if (z !== card.z) {
            card.z = z;
            DomMap[card.rank + '_' + card.suit].style.zIndex = z;
            // DomMap[card.rank + '_' + card.suit].style[Modernizr.prefixed('zIndex')] = z;
        }
    },

    show: function(card) {
        Card.removeCustomClass(card, 'hidden');
    },

    hide: function(card) {
        Card.addCustomClass(card, 'hidden');
    },

    setPlayerHand: function(card) {
        Card.addCustomClass(card, 'playerHand');
        Card.removeCustomClass(card, 'computerHand');
    },

    setComputerHand: function(card) {
        Card.addCustomClass(card, 'computerHand');
        Card.removeCustomClass(card, 'playerHand');
    },

    click: function(card) {
        DomMap[card.rank + '_' + card.suit].click();
    },

    addCustomClass: function(card, theClass) {
        DomMap[card.rank + '_' + card.suit].classList.add(theClass);
    },

    removeCustomClass: function(card, theClass) {
        DomMap[card.rank + '_' + card.suit].classList.remove(theClass);
    },

    addClickListener: function(card) {
        DomMap[card.rank + '_' + card.suit].addEventListener('click', function() {
            var cardClickedEvent = document.createEvent('UIEvents');
            cardClickedEvent.initEvent('cardClicked', true, true);
            var suit, rank;
            var classArray = this.className.split(' ');
            for (var i = 0; i < classArray.length; i++) {
                if (
                    classArray[i] === 'heart' ||
                    classArray[i] === 'club' ||
                    classArray[i] === 'diamond' ||
                    classArray[i] === 'spade'
                ) {
                    suit = classArray[i];
                } else if (classArray[i].charAt(0) === '_') {
                    rank = classArray[i].substr(1);
                }
            }

            cardClickedEvent.card = Deck.getCard(Config.game.deck, suit, rank);
            document.dispatchEvent(cardClickedEvent);
        });
    },

    createCard: function(newSuit, newValue) {
        var result = {
            rank: newValue,
            suit: newSuit,
            x: 0,
            y: 0,
            rotation: 0,
            scale: 1,
            z: 1,
            numericRank: 0
        };

        result.numericRank = Card.findNumericRank(result);

        Card.addClickListener(result);
        Card.hide(result);
        Card.removeCustomClass(result, 'meld');

        return result;
    },

    cleanCardsCache: function(cards) {
        for (var i = 0; i < cards.length; i++) {
            cards[i].x = 0;
            cards[i].y = 0;
            cards[i].z = 0;
            cards[i].scale = 0;
            cards[i].rotation = 0;
        }
    }
};

var Game = {
    finishHand: function() {
        // update scores
        if (Config.players[0].hand.cards.length === 0) {
            Config.players[1].score += Hand.getScore(Config.players[1].hand);
        }
        else if (Config.players[1].hand.cards.length === 0) {
            Config.players[0].score += Hand.getScore(Config.players[0].hand);
        }

        Config.players[0].roundScore = Hand.getScore(Config.players[0].hand);
        Config.players[1].roundScore = Hand.getScore(Config.players[1].hand);

        App.updateScoreDOM();

        // Show computers hand if any cards left
        Config.opponentY += 125;
        Game.layout(Config.game);
        Hand.show(Config.players[1].hand);
        App.saveState();

        if (Config.players[0].score >= 100 || Config.players[1].score >= 100) {
            var bestScore = App.getBestScore();
            if (Config.players[0].score < bestScore) {
                App.setBestScore(Config.players[0].score);
            }

            App.showWinnerModal();
        }
        else {
            App.showContinueModal();
        }
    },

    nextHand: function(game) {
        if (Config.opponentY > -50) {
            Config.opponentY -= 125;
        }

        Config.computerGoesFirst = !Config.computerGoesFirst;

        for (var cardIndex in game.deck.cards) {
            Card.removeCustomClass(game.deck.cards[cardIndex], 'meld');
            Card.hide(game.deck.cards[cardIndex]);
        }
        game.discards = [];
        game.melds = [];
        Hand.empty(Config.players[0].hand);
        Hand.empty(Config.players[1].hand);
        game.stock = game.deck.cards.slice(0);

        setTimeout(function() {
            App.saveState();
            Game.deal(Config.game);
        }, Config.animationTime);

        Game.layout(Config.game);
        Deck.shuffle(game.deck);
    },

    toggleTurn: function(game) {
        setTimeout(function() {
            game.computerTurn = !game.computerTurn;

            // check for a winner
            if (Config.players[0].hand.cards.length === 0 || Config.players[1].hand.cards.length === 0) {
                Game.finishHand();
            }
            else {
                // Check for empty stock
                if (game.stock.length === 0) {
                    game.stock = game.discards.splice(1);
                    Game.layout(game);
                }

                if (game.computerTurn) {
                    Player.autoPlay(Config.players[1]);
                }
                else {
                    game.state = states.DRAW;
                    Game.updateHintText(game);
                }
            }
            App.saveState();
        }, Config.animationTime);
    },

    // Call this to update the display
    layout: function(game) {

        if (game.melds.length > 0 && Config.handsCenteredOn !== 0.53) {
            Game.centerHandsOn(0.58);
        } else if (Config.handsCenteredOn !== 0.5) {
            Game.centerHandsOn(0.5);
        }

        // if on mobile, centered always
        if (Util.onMobile) {
            Game.centerHandsOn(0.5);
        }

        Game.layoutMelds(game);
        Game.layoutStock(game);
        Game.layoutDiscards(game);
        Hand.layout(Config.players[0].hand);
        Hand.layout(Config.players[1].hand);
        Game.layoutHintText(game);
    },

    // This will change where the players hands are centered around.
    centerHandsOn: function(percent) {
        Config.handsCenteredOn = percent;
    },

    deal: function(game) {
        // To begin, every card from the deck is in the stock
        game.stock = game.deck.cards.slice(0);

        // Each player gets 10 cards in their hand
        Config.players[0].hand = Hand.createHand(game.stock.splice(0, 10), false);
        Hand.order(Config.players[0].hand);
        Config.players[1].hand = Hand.createHand(game.stock.splice(0, 10), true);
        Hand.order(Config.players[1].hand);

        // Update the display according to the deal
        Game.layout(game);

        // Wait for initial layout to finish
        setTimeout(function() {
            // Put the first card into the discard pile
            Config.game.discards.push(Config.game.stock.pop());

            // Just render the discards
            Game.layoutDiscards(Config.game);

            // Wait for the card to be moved onto the discard pile
            setTimeout(function() {
                // Turn over the card
                Card.show(Config.game.discards[0]);

                App.saveState();

                // Start the game
                Game.start(Config.game);
            }, Config.animationTime + 50);
        }, Config.animationTime + 50);
    },

    start: function(game) {
        if (Config.computerGoesFirst) {
            game.computerTurn = true;
            Player.autoPlay(Config.players[1]);
        }
        else {
            game.computerTurn = false;
            game.state = states.DRAW;
            Game.updateHintText(game);
        }
    },

    layoutStock: function(game) {
        var distanceBetween = Util.onMobile ? 25 : 100;
        var zIndex = 10;
        for (var stockIndex = 0; stockIndex < game.stock.length; stockIndex++) {
            Card.updateLayout(
                game.stock[stockIndex],
                (Config.screenWidth * Config.handsCenteredOn) - distanceBetween - (Config.cardWidth / 2),
                Config.stockY,
                0
            );
            Card.setZ(Config.game.stock[stockIndex], zIndex++);
            Card.hide(game.stock[stockIndex]);
        }
    },

    layoutHintText: function(game) {
        // Stock hint arrow
        var textBoxWidth = DomMap.textBox.clientWidth;
        var newX = (Config.screenWidth * Config.handsCenteredOn) - (textBoxWidth / 2);
        var newY = Config.stockY + 200;

        DomMap.textBox.style.left = newX  + 'px';
        DomMap.textBox.style.top = newY + 'px';
    },

    updateHintText: function(game) {
        if (game.state === states.DRAW) {
            DomMap.textBox.style.opacity = 1;
            DomMap.textBox.textContent = "Draw a card from above";
        }
        else if (game.state === states.DISCARD) {
            DomMap.textBox.style.opacity = 1;
            DomMap.textBox.textContent = "Discard one of your cards";
        }
        else {
            DomMap.textBox.textContent = "";
            DomMap.textBox.style.opacity = 0;
        }
    },

    layoutDiscards: function(game) {
        var zIndex = 10;
        for (var i = 0; i < game.discards.length; i++) {
            Card.updateLayout(
                Config.game.discards[i],
                (Config.screenWidth * Config.handsCenteredOn) + 100 - (Config.cardWidth / 2),
                Config.stockY,
                0
            );
            Card.setZ(game.discards[i], zIndex++);
            Card.show(game.discards[i]);
        }
    },

    layoutMelds: function(game) {
        var yDistance = (Config.screenHeight - 30) / game.melds.length;
        var currentMeldY = Util.onMobile ? 100 : 15;
        var zIndex = 10;
        var cardScale;
        var cardOffset;
        if (game.melds.length < 4) {
            cardScale = 0.8;
            cardOffset = 10;
        }
        else if (game.melds.length < 5) {
            cardScale = 0.7;
            cardOffset = 40;
        }
        else if (game.melds.length < 6) {
            cardScale = 0.6;
            cardOffset = 53;
        }
        else {
            cardScale = 0.5;
            cardOffset = 60;
        }

        if (Util.onMobile) {
            cardScale = 0.6;
            cardOffset = 100; // increase to make melds closer together
        }

        for (var meldIndex in game.melds) {
            var currentCardX = Util.onMobile ? 0 : 25; // meld starting distance from left
            for(var cardIndex in game.melds[meldIndex]) {
                Card.updateLayout(game.melds[meldIndex][cardIndex], currentCardX, currentMeldY, 0, cardScale);
                Card.setZ(game.melds[meldIndex][cardIndex], zIndex++);
                Card.removeCustomClass(game.melds[meldIndex][cardIndex], 'computerHand');
                Card.removeCustomClass(game.melds[meldIndex][cardIndex], 'playerHand');
                Card.addCustomClass(game.melds[meldIndex][cardIndex], 'meld');
                Card.show(game.melds[meldIndex][cardIndex]);
                currentCardX += 23;
            }

            if (yDistance > Config.cardHeight - cardOffset) {
                currentMeldY += Config.cardHeight - cardOffset;
            }
            else {
                currentMeldY += yDistance;
            }
        }
    },

    createGame: function() {
        var result = {
            deck: Deck.createDeck(),
            discards: [],
            stock: [],
            melds: [],
            computerTurn: false,
            state: states.DRAW
        };

        return result;
    }
};

var Hand = {
    createHand: function(cards, computer) {
        var result = {
            cards: cards,
            isComputer: computer,
            isPretend: false // When testing for valid melds, we create some pretend hands
        };

        return result;
    },

    getScore: function(hand) {
        var score = 0;
        for (var cardIndex in hand.cards) {
            if (hand.cards[cardIndex].numericRank > 9) {
                score += 10;
            }
            else {
                score += hand.cards[cardIndex].numericRank;
            }
        }

        return score;
    },

    order: function(hand) {
        // order by rank
        if (Array.isArray(hand.cards)) {
            hand.cards.sort(function(a, b) {
                if (a.numericRank > b.numericRank) {
                    return 1;
                }
                else if (a.numericRank === b.numericRank) {
                    return a.suit < b.suit;
                }
                else {
                    return -1;
                }
            });
        }
    },

    layout: function(hand) {
        var isOdd = (hand.cards.length & 1) === 1;
        var ANGLE_BETWEEN_CARDS = Util.onMobile ? 2.2 : 3;
        var cardsOnEachSide;
        var xValue;
        var yValue;
        var angle;
        var curZindex = 10;

        if (isOdd) {
            cardsOnEachSide = (hand.cards.length - 1) / 2;

            if (hand.isComputer) {
                angle = ANGLE_BETWEEN_CARDS * cardsOnEachSide;
            }
            else {
                angle = -ANGLE_BETWEEN_CARDS * cardsOnEachSide;
            }
        }
        else {
            cardsOnEachSide = (hand.cards.length) / 2;

            if (hand.isComputer) {
                angle = ANGLE_BETWEEN_CARDS * cardsOnEachSide - (ANGLE_BETWEEN_CARDS / 2);
            }
            else {
                angle = -ANGLE_BETWEEN_CARDS * cardsOnEachSide + (ANGLE_BETWEEN_CARDS / 2);
            }
        }

        xValue = (Config.screenWidth * Config.handsCenteredOn) - (Config.cardWidth / 2); // Find starting point

        if (hand.isComputer) {
            yValue = Config.opponentY;
        }
        else {
            yValue = Config.playerY;
        }

        for (var i = 0; i < hand.cards.length; i++) {
            Card.updateLayout(hand.cards[i], xValue, yValue, angle);

            if (hand.isComputer) {
                angle -= ANGLE_BETWEEN_CARDS;
                Card.hide(hand.cards[i]);
                Card.setComputerHand(hand.cards[i]);
            }
            else {
                angle += ANGLE_BETWEEN_CARDS;
                Card.show(hand.cards[i]);
                Card.setPlayerHand(hand.cards[i]);
            }

            Card.setZ(hand.cards[i], curZindex++);
        }
    },

    layDownMelds: function(hand) {
        var setLayedDown = Hand.layDownSets(hand);
        var runLayedDown = Hand.layDownRuns(hand);
        var additions = false;
        while (Hand.layDownAdditions(hand)) {
            additions = true;
        }

        return setLayedDown || runLayedDown || additions;
    },

    layDownAdditions: function(hand) {
        var result = false;

        var isSetMeld = function(meld) {
            return meld[0].numericRank === meld[1].numericRank;
        };

        for (var cardIndex = 0; cardIndex < hand.cards.length; cardIndex++) {
            for (var meldIndex in Config.game.melds) {
                var card = hand.cards[cardIndex];
                var meld = Config.game.melds[meldIndex];
                var removed = false;

                if (isSetMeld(meld)) {
                    if (card.numericRank === meld[0].numericRank) {
                        removed = true;
                        if (!hand.isPretend) {
                            meld.push(card);
                        }
                    }
                }
                else {
                    if (meld[0].suit === card.suit) {
                        if (meld[0].numericRank - 1 === card.numericRank) {
                            removed = true;
                            meld.unshift(card);
                        }
                        else if (meld[meld.length - 1].numericRank + 1 === card.numericRank) {
                            removed = true;
                            if (!hand.isPretend) {
                                meld.push(card);
                            }
                        }
                    }
                }

                if (removed) {
                    hand.cards.splice(cardIndex, 1);
                    cardIndex--;
                    result = true;
                    break;
                }
            }
        }

        return result;
    },

    getSetLength: function(hand, startIndex) {
        var length = 1;
        var currentIndex = startIndex + 1;
        while (currentIndex < hand.cards.length && hand.cards[currentIndex].rank === hand.cards[startIndex].rank) {
            length++;
            currentIndex++;
        }

        return length;
    },

    indexOf: function(hand, suit, numericRank) {
        for (var i = 0; i < hand.cards.length; i++) {
            if (hand.cards[i].suit === suit && hand.cards[i].numericRank === numericRank) {
                return i;
            }
        }

        return -1;
    },

    cardIsPartOfRun: function(hand, index) {
        var result = [];

        var offset = 1;
        var theCard = hand.cards[index];

        var place = index;

        do {
            result.push(place);
            place = Hand.indexOf(hand, theCard.suit, theCard.numericRank + offset);
            offset++;
        } while (place >= 0);

        return result;
    },

    getPotentialRuns: function(hand, index) {
        var result = [index];
        var theCard = hand.cards[index];

        var downward = Hand.indexOf(hand, theCard.suit, theCard.numericRank - 1);
        var upward = Hand.indexOf(hand, theCard.suit, theCard.numericRank + 1);
        if (downward >= 0) {
            result.unshift(downward);
        }
        if (upward >= 0) {
            result.push(upward);
        }

        return result;
    },

    getPotentialSets: function(hand, index) {
        var result = [];
        var theCard = hand.cards[index];

        for (var i = 0; i < hand.cards.length; i++) {
            if (hand.cards[i].numericRank === theCard.numericRank) {
                result.push(i);
            }
        }

        return result;
    },

    getPotentialNonContRuns: function(hand, index) {
        var result = [index];
        var theCard = hand.cards[index];

        var downward = Hand.indexOf(hand, theCard.suit, theCard.numericRank - 2);
        var upward = Hand.indexOf(hand, theCard.suit, theCard.numericRank + 2);
        if (downward >= 0) {
            result.unshift(downward);
        }
        if (upward >= 0) {
            result.push(upward);
        }

        return result;
    },

    layDownSets: function(hand) {
        var result = false;

        // look at each card
        for (var i = 0; i < hand.cards.length; i++) {
            var length = Hand.getSetLength(hand, i);
            if (length > 2) {
                var removed = hand.cards.splice(i, length);
                Hand.layDownMeld(hand, removed);
                result = true;
            }
        }

        return result;
    },

    layDownRuns: function(hand) {
        var result = false;

        // look at each card
        for (var i = 0; i < hand.cards.length; i++) {
            var run = Hand.cardIsPartOfRun(hand, i);

            if (run.length > 2) {
                var removed = [];
                for (var runCardIndex = run.length - 1; runCardIndex >= 0; runCardIndex--) {
                    var card = hand.cards.splice(run[runCardIndex], 1)[0];
                    removed.unshift(card);
                }
                Hand.layDownMeld(hand, removed);
                result = true;
            }
        }

        return result;
    },

    layDownMeld: function(hand, cardsToLayDown) {
        if (!hand.isPretend) {
            Config.game.melds.push(cardsToLayDown);
        }
    },

    addCard: function(hand, card) {
        hand.cards.push(card);
        Hand.order(hand);
    },

    hasThisNumberCard: function(hand, card) {
        return Hand.indexOf(hand, 'heart', card.numericRank) >= 0 ||
            Hand.indexOf(hand, 'diamond', card.numericRank) >= 0 ||
            Hand.indexOf(hand, 'club', card.numericRank) >= 0 ||
            Hand.indexOf(hand, 'spade', card.numericRank) >= 0;
    },

    hasCardNearThis: function(hand, card) {
        return Hand.indexOf(hand, card.suit, card.numericRank - 1) >= 0 ||
            Hand.indexOf(hand, card.suit, card.numericRank + 1) >= 0;
    },

    wouldResultInMeld: function(hand, card) {
        // Make new fake hand with the card added
        var fakeHand = Hand.createHand(hand.cards.concat([card]));
        fakeHand.isPretend = true;
        Hand.order(fakeHand);
        var result = Hand.layDownMelds(fakeHand);
        fakeHand = null;
        return result;
    },

    getWorth: function(hand, index) {
        var lookingFor = [];
        var worth = 0;
        var candidates = 0;
        var theCard = hand.cards[index];

        var updateCandidateBasedOnUsed = function(candidates, cardsLookingFor) {
            var newCandidates = candidates;
            var lookingIndex;
            var discardCard, meldCard;

            // Look for unavailable cards of same numericRank
            for (var discardIndex in Config.game.discards) {
                discardCard = Config.game.discards[discardIndex];
                for (lookingIndex in cardsLookingFor) {
                    if (
                        discardCard.numericRank === cardsLookingFor[lookingIndex].numericRank &&
                        discardCard.suit === cardsLookingFor[lookingIndex].suit
                    ) {
                        newCandidates--;
                    }
                }
            }

            // Look for unavailable cards of same numericRank
            for (var meldIndex in Config.game.melds) {
                for (var cardIndex in Config.game.melds[meldIndex]) {
                    meldCard = Config.game.melds[meldIndex][cardIndex];
                    for (lookingIndex in cardsLookingFor) {
                        if (
                            meldCard.numericRank === cardsLookingFor[lookingIndex].numericRank &&
                            meldCard.suit === cardsLookingFor[lookingIndex].suit
                        ) {
                            newCandidates--;
                        }
                    }
                }
            }

            return newCandidates;
        };

        var setCards = Hand.getPotentialSets(hand, index);
        var runCards = Hand.getPotentialRuns(hand, index);
        var ncRunCards = Hand.getPotentialNonContRuns(hand, index);

        if (setCards.length > 1) {
            candidates += 2;

            // Look for unavailable cards of same numericRank
            lookingFor.concat([
                {suit: 'heart', numericRank: theCard.numericRank},
                {suit: 'diamond', numericRank: theCard.numericRank},
                {suit: 'spade', numericRank: theCard.numericRank},
                {suit: 'club', numericRank: theCard.numericRank}
            ]);
        }
        if (runCards.length > 1) {
            candidates += 2;

            // Look for unavailable cards at either end of run
            lookingFor.concat([
                {
                    suit: hand.cards[runCards[0]].suit,
                    numericRank: hand.cards[runCards[0]].numericRank - 1
                },
                {
                    suit: hand.cards[runCards[0]].suit,
                    numericRank: hand.cards[runCards[runCards.length - 1]].numericRank + 1
                }
            ]);
        }
        if (ncRunCards.length > 1) {
            candidates += 1;
            lookingFor.concat([
                {
                    suit: hand.cards[ncRunCards[0]].suit,
                    numericRank: hand.cards[ncRunCards[0]].numericRank + 1
                }
            ]);
        }
        candidates = updateCandidateBasedOnUsed(candidates, lookingFor);
        worth = candidates / 52;

        return worth;
    },

    chooseDiscard: function(hand) {
        var lowestWorth = Number.MAX_VALUE;
        var leastValueableIndex = hand.cards.length - 1;
        for (var cardIndex = hand.cards.length - 1; cardIndex >= 0; cardIndex--) {
            var thisWorth = Hand.getWorth(hand, cardIndex);

            // short circuit on first card with a worth of 0
            if (thisWorth === 0) {
                return hand.cards[cardIndex];
            }

            // Otherwise see if there has been a card worth less
            if (thisWorth < lowestWorth) {
                lowestWorth = thisWorth;
                leastValueableIndex = cardIndex;
            }
        }

        return hand.cards[leastValueableIndex];
    },

    empty: function(hand) {
        hand.cards = [];
    },

    show: function(hand) {
        for (var cardIndex in hand.cards) {
            Card.show(hand.cards[cardIndex]);
        }
    }
};

var Player = {
    createPlayer: function(isComputer) {
        var result = {
            hand: null,
            isComputer: isComputer,
            score: 0
        };

        return result;
    },

    startFresh: function(player, isComputer) {
        player.hand = null;
        player.isComputer = isComputer;
        player.score = 0;
    },

    shouldChoose: function(player, cardShowing) {
        // If it will get you a meld, do it
        if (Hand.wouldResultInMeld(player.hand, cardShowing)) {
            return true;
        }
        // If you have no cards near it, don't
        else if (!Hand.hasThisNumberCard(player.hand, cardShowing) && !Hand.hasCardNearThis(player.hand, cardShowing)) {
            return false;
        }
        else {
            // Otherwise, pick randomly
            return Math.random() > 0.5;
        }
    },

    autoPlay: function(player) {
        var layedDown = Hand.layDownMelds(player.hand);
        Game.layout(Config.game);

        // Check for winner
        if (player.hand.cards.length === 0) {
            Config.game.state = null;
            Game.toggleTurn(Config.game);
            return;
        }

        var delay = layedDown ? Config.animationTime : 0;

        setTimeout(function() {
            if (Player.shouldChoose(player, Config.game.discards[Config.game.discards.length - 1])) {
                Player.draw(player, Config.game.discards[Config.game.discards.length - 1]);
            }
            else {
                Player.draw(player, Config.game.stock[Config.game.stock.length - 1]);
            }

            setTimeout(function() {
                if (player.hand.cards.length > 0) {
                    Player.discard(player, Hand.chooseDiscard(player.hand));
                }

                Game.layout(Config.game);
            }, Config.animationTime);
        }, delay);
    },

    draw: function(player, card) {
        Config.game.state = null;
        if (Card.equals(card, Config.game.discards[Config.game.discards.length - 1])) {
            Player.drawFromDiscards(player);
        }
        else if (Card.equals(card, Config.game.stock[Config.game.stock.length - 1])) {
            Player.drawFromStock(player);
        }
        else {
            Config.game.state = states.DRAW;
            return;
        }

        Game.layout(Config.game);

        setTimeout(function() {
            Hand.layDownMelds(player.hand);
            Game.layout(Config.game);
            if (player.hand.cards.length === 0) {
                Config.game.state = null;
                Game.toggleTurn(Config.game);
            }
            else {
                Config.game.state = states.DISCARD;
                App.saveState();
            }
            Game.updateHintText(Config.game);
        }, Config.animationTime);
    },

    drawFromStock: function(player) {
        if (Config.game.stock.length > 0) {
            var card = Config.game.stock.pop();
            Hand.addCard(player.hand, card);
        }
    },

    drawFromDiscards: function(player) {
        if (Config.game.discards.length > 0) {
            var card = Config.game.discards.pop();
            Hand.addCard(player.hand, card);
        }
    },

    discard: function(player, card) {
        Config.game.state = null;
        // var playerIndex;
        // if (Config.game.computerTurn) {
        //   playerIndex = 1;
        // }
        // else {
        //   playerIndex = 0;
        // }

        var placeInHand = Hand.indexOf(player.hand, card.suit, card.numericRank);

        if (placeInHand >= 0) {
            player.hand.cards.splice(placeInHand, 1);
            Config.game.discards.push(card);
            Card.show(card);
            Config.game.state = null;
            Game.updateHintText(Config.game);
            Game.toggleTurn(Config.game);
        }
        else {
            Config.game.state = states.DISCARD;
        }
    }
};

let Config = {
    computerGoesFirst: false,
    game: Game.createGame(),
    handsCenteredOn: 0.5,
    cardWidth: 113, // 90
    cardHeight: 158, // 126
    screenWidth: null,
    screenHeight: null,
    stockY: null,
    playerY: null,
    opponentY: null,
    opponentScore: null,
    animationTime: 600,
    stockHintX: null,
    stockHintY: null,
    discardHintX: null,
    discardHintY: null,
    players: [
        Player.createPlayer(false),
        Player.createPlayer(true)
    ]
};

var App = {
    restartGame: function() {
        localStorage.removeItem('state');
        Config = {
            computerGoesFirst: false,
            game: Game.createGame(),
            handsCenteredOn: 0.5,
            cardWidth: 113, // 90
            cardHeight: 158, // 126
            screenWidth: null,
            screenHeight: null,
            stockY: null,
            playerY: null,
            opponentY: null,
            opponentScore: null,
            animationTime: 600,
            stockHintX: null,
            stockHintY: null,
            discardHintX: null,
            discardHintY: null,
            players: [
                Player.createPlayer(false),
                Player.createPlayer(true)
            ]
        };
        App.updateLayoutVariables();
        App.updateScoreDOM(); // check
        Game.deal(Config.game);
        window.aaw.refreshAdunits([
            '/127208727/PRO_D_728x90_2',
            '/127208727/PRO_D_728x90_1',
            '/127208727/PRO_D_300x600_2',
            '/127208727/PRO_D_160x600_2'
        ]);
    },
    continueGame: function() {

        App.hideContinueModal();
        App.updateLayoutVariables();
        //Game.deal(Config.game);
        Game.layout(Config.game);
        console.log('Resuming. game state is: ', Config.game.state)
        App.updateScoreDOM(); // check
        if (Config.game.state === null) {
            Config.game.state = 0;
        }

        Game.updateHintText(Config.game);
        if (Config.players[0].hand.cards.length === 0 || Config.players[1].hand.cards.length === 0) {
            Game.nextHand(Config.game);
        }else{
            window.location.reload(false);
        }
        window.aaw.refreshAdunits([
            '/127208727/PRO_D_728x90_2',
            '/127208727/PRO_D_728x90_1',
            '/127208727/PRO_D_300x600_2',
            '/127208727/PRO_D_160x600_2'
        ]);
    },
    updateLayoutVariables: function() {
        var gameArea = document.getElementById('play-table');

        Config.screenWidth = gameArea.clientWidth;
        Config.screenHeight = gameArea.clientHeight;

        // Set PlayerY
        Config.playerY = Config.screenHeight - Config.cardHeight + 25;

        // Set OpponentY (Where the opponents cards are vertically)
        // if (Config.screenHeight < 506) {
        //   Config.opponentY = -175;
        // }
        // else if (Config.screenHeight >= 506 && Config.screenHeight < 578) {
        //   Config.opponentY = -150;
        // }
        // else {
        //   Config.opponentY = -50;
        // }

        Config.opponentY = Util.onMobile ? -90 : -50;

        // Opponent Score
        // domMap.computerScore.style.top = (Config.opponentY + Config.cardHeight + 10) + 'px';

        // Set StockY (where the middle two cards are vertically)
        if (Config.screenHeight < 506) {
            Config.stockY = 50;
        }
        else if (Config.screenHeight >= 506 && Config.screenHeight < 578) {
            Config.stockY = 140;
        }
        else if (Config.screenHeight >= 578 && Config.screenHeight < 625) {
            Config.stockY = 135;
        }
        else if (Config.screenHeight >= 625 && Config.screenHeight < 680) {
            Config.stockY = 170;
        }
        else if (Config.screenHeight >= 680 && Config.screenHeight < 750) {
            Config.stockY = 210;
        }
        else {
            Config.stockY = 250;
        }

        Config.stockY = 200;

    },
    removePreload: function() {
        var elements = document.querySelectorAll('.preload');
        for (var i = 0; i < elements.length; i++) {
            elements[i].classList.remove('preload');
        }
    },
    init: function() {
        // The preload class will prevent css transitions while the page is being loaded
        // Once the page is loaded we need to remove it to re-enable the transitions
        App.removePreload();

        // When the browser resizes we need to update our layout
        window.addEventListener('resize:end', function() {
            App.updateLayoutVariables();
            Game.layout(Config.game);
        }, false);

        document.addEventListener('cardClicked', function(e) {
            // Nothing should happend unless it is the players turn

            if (!Config.game.computerTurn) {

                // Depending on what we are waiting for, act accordingly
                switch (Config.game.state) {
                    case states.DRAW:
                        Player.draw(Config.players[0], e.card);
                        break;
                    case states.DISCARD:
                        Player.discard(Config.players[0], e.card);
                        Game.layout(Config.game);
                        break;
                    default:
                        throw new Error('shouldnt be getting here!')
                }
            }
        });

        var continueButton = document.querySelectorAll('#js-continue-modal > .button')[0];
        continueButton.addEventListener('click', function() {
            App.continueGame();
        });

        var playAgainButton = document.querySelectorAll('#js-winner-modal > .button')[0];
        playAgainButton.addEventListener('click', function() {
            App.restartGame();
        });

        var restartButton = document.querySelectorAll('#js-restart-button')[0];
        restartButton.addEventListener('click', function() {
            if (window.confirm('Are you sure you want to restart?')) {
                App.restartGame();
            }
        });

        // Set up fastclick for mobile devices
        window.addEventListener('load', function() {
            FastClick.attach(document.body);
            document.ontouchmove = function(e){
                e.preventDefault();
            };
        }, false);

        // Load previously save state
        var resuming = App.retrieveState();

        App.updateLayoutVariables();

        if (resuming) {
            Game.layout(Config.game);

            console.log('Resuming. game state is: ', Config.game.state)
            App.updateScoreDOM(); // check

            if (Config.game.state === null) {
                Config.game.state = 0;
            }

            Game.updateHintText(Config.game);
            if (Config.players[0].hand.cards.length === 0 || Config.players[1].hand.cards.length === 0) {
                Game.nextHand(Config.game);
            }
        }
        else {
            Game.deal(Config.game);
        }
    },

    updateScoreDOM: function() {
        document.querySelectorAll('.playerScore > .scoreValue')[0].textContent = Config.players[0].score;
        document.querySelectorAll('.computerScore > .scoreValue')[0].textContent = Config.players[1].score;
    },

    showModal: function(id) {
        var modal = document.querySelectorAll('#' + id)[0];
        modal.style.transform = 'translateX(-50%) translateZ(2px)';
        // modal.style[Modernizr.prefixed('transform')] = 'translateX(-50%) translateZ(2px)';
    },

    hideModal: function(id) {
        var modal = document.querySelectorAll('#' + id)[0];
        // modal.style[Modernizr.prefixed('transform')] = 'translateX(-201%) translateZ(2px)';
        modal.style.transform = 'translateX(-201%) translateZ(2px)';
    },

    showContinueModal: function() {

        document.querySelectorAll('#js-previous-round-human')[0].textContent = Config.players[0].roundScore;
        document.querySelectorAll('#js-previous-round-computer')[0].textContent = Config.players[1].roundScore;

        document.querySelectorAll('#js-total-human-score')[0].textContent = Config.players[0].score;
        document.querySelectorAll('#js-total-computer-score')[0].textContent = Config.players[1].score;

        // var bestScore = App.getBestScore();
        // var bestScoreElement = document.querySelectorAll('#bestScore')[0];
        // if (bestScore === 0) {
        //   bestScoreElement.classList.add('hide');
        // }
        // else {
        //   bestScoreElement.classList.remove('hide');

        //   var scoreValueElement = document.querySelectorAll('#bestScore #scoreValue')[0];
        //   scoreValueElement.textContent = bestScore;
        // }

        App.showModal('js-continue-modal');
    },

    hideContinueModal: function() {
        App.hideModal('js-continue-modal');
    },

    showWinnerModal: function() {
        if (Config.players[0].score < Config.players[1].score) {
            document.querySelectorAll('#js-winner-modal .gameResult')[0].textContent = 'Amazing! You Won!';
            window.ga('send', 'event', 'game winner', 'human');
            App.dropConfetti();
        }
        else if (Config.players[0].score === Config.players[1].score) {
            document.querySelectorAll('#js-winner-modal .gameResult')[0].textContent = 'It\'s a Tie!';
            window.ga('send', 'event', 'game winner', 'tie');
        }
        else {
            window.ga('send', 'event', 'game winner', 'computer');
            document.querySelectorAll('#js-winner-modal .gameResult')[0].textContent = 'You Lost!';
        }

        document.querySelectorAll('#js-winner-your-score')[0].textContent = Config.players[0].score;
        document.querySelectorAll('#js-winner-opponent-score')[0].textContent = Config.players[1].score;

        // var bestScore = App.getBestScore();
        // var scoreValueElement = document.querySelectorAll('#js-winner-best-score #winnerScoreValue')[0];
        // scoreValueElement.textContent = bestScore;

        App.showModal('js-winner-modal');
    },

    hideWinnerModal: function() {
        App.hideModal('js-winner-modal');
    },

    saveState: function() {
        localStorage.setItem('state', JSON.stringify(Config));
    },

    retrieveState: function() {
        var state = localStorage.getItem('state');
        if (state !== null) {
            Config = JSON.parse(state);

            // removed cached position values from cards
            Card.cleanCardsCache(Config.game.deck.cards);
            Card.cleanCardsCache(Config.players[0].hand.cards);
            Card.cleanCardsCache(Config.players[1].hand.cards);
            Card.cleanCardsCache(Config.game.discards);
            Card.cleanCardsCache(Config.game.stock);
            for (var j = 0; j < Config.game.melds.length; j++) {
                Card.cleanCardsCache(Config.game.melds[j]);
            }

            Config.game.computerTurn = false;
            Config.handsCenteredOn = 0;

            return true;
        }
        else {
            return false;
        }
    },

    getBestScore: function() {
        var score = localStorage.getItem('bestScore');
        if (score === null) {
            score = 0;
        }

        return score;
    },
    setBestScore: function(value) {
        localStorage.setItem('bestScore', value);
    },
    dropConfetti: showConfetti
};

export default App;
