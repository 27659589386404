var DomMap = {
  'A_spade': document.querySelectorAll('._A.spade')[0],
  '2_spade': document.querySelectorAll('._2.spade')[0],
  '3_spade': document.querySelectorAll('._3.spade')[0],
  '4_spade': document.querySelectorAll('._4.spade')[0],
  '5_spade': document.querySelectorAll('._5.spade')[0],
  '6_spade': document.querySelectorAll('._6.spade')[0],
  '7_spade': document.querySelectorAll('._7.spade')[0],
  '8_spade': document.querySelectorAll('._8.spade')[0],
  '9_spade': document.querySelectorAll('._9.spade')[0],
  '10_spade': document.querySelectorAll('._10.spade')[0],
  'J_spade': document.querySelectorAll('._J.spade')[0],
  'Q_spade': document.querySelectorAll('._Q.spade')[0],
  'K_spade': document.querySelectorAll('._K.spade')[0],

  'A_heart': document.querySelectorAll('._A.heart')[0],
  '2_heart': document.querySelectorAll('._2.heart')[0],
  '3_heart': document.querySelectorAll('._3.heart')[0],
  '4_heart': document.querySelectorAll('._4.heart')[0],
  '5_heart': document.querySelectorAll('._5.heart')[0],
  '6_heart': document.querySelectorAll('._6.heart')[0],
  '7_heart': document.querySelectorAll('._7.heart')[0],
  '8_heart': document.querySelectorAll('._8.heart')[0],
  '9_heart': document.querySelectorAll('._9.heart')[0],
  '10_heart': document.querySelectorAll('._10.heart')[0],
  'J_heart': document.querySelectorAll('._J.heart')[0],
  'Q_heart': document.querySelectorAll('._Q.heart')[0],
  'K_heart': document.querySelectorAll('._K.heart')[0],

  'A_diamond': document.querySelectorAll('._A.diamond')[0],
  '2_diamond': document.querySelectorAll('._2.diamond')[0],
  '3_diamond': document.querySelectorAll('._3.diamond')[0],
  '4_diamond': document.querySelectorAll('._4.diamond')[0],
  '5_diamond': document.querySelectorAll('._5.diamond')[0],
  '6_diamond': document.querySelectorAll('._6.diamond')[0],
  '7_diamond': document.querySelectorAll('._7.diamond')[0],
  '8_diamond': document.querySelectorAll('._8.diamond')[0],
  '9_diamond': document.querySelectorAll('._9.diamond')[0],
  '10_diamond': document.querySelectorAll('._10.diamond')[0],
  'J_diamond': document.querySelectorAll('._J.diamond')[0],
  'Q_diamond': document.querySelectorAll('._Q.diamond')[0],
  'K_diamond': document.querySelectorAll('._K.diamond')[0],

  'A_club': document.querySelectorAll('._A.club')[0],
  '2_club': document.querySelectorAll('._2.club')[0],
  '3_club': document.querySelectorAll('._3.club')[0],
  '4_club': document.querySelectorAll('._4.club')[0],
  '5_club': document.querySelectorAll('._5.club')[0],
  '6_club': document.querySelectorAll('._6.club')[0],
  '7_club': document.querySelectorAll('._7.club')[0],
  '8_club': document.querySelectorAll('._8.club')[0],
  '9_club': document.querySelectorAll('._9.club')[0],
  '10_club': document.querySelectorAll('._10.club')[0],
  'J_club': document.querySelectorAll('._J.club')[0],
  'Q_club': document.querySelectorAll('._Q.club')[0],
  'K_club': document.querySelectorAll('._K.club')[0],

  'textBox': document.getElementById('text-box'),
  'computerScore': document.querySelectorAll('.computerScore')[0],
  'playerScore': document.querySelectorAll('.playerScore')[0]
};

export default DomMap;
